<template>
  <!-- prettier-ignore -->
  <div class="entry">
    <div
      class="h2 entry__title"
      v-text="$t('screen.productWidget.entry.title')"
    />

    <div class="entry__login">
      <slot name="login" />
    </div>

    <basic-form
      name="productWidget__entry"
      class="entry__form"
      :auto-focus="false"
      :validator="v"
      @submit="submit"
    >
      <basic-location
        v-model="formData.address"
        class="entry__location"
        name="address"
        :contract-start-date="basketStore.basket.contractStartDate"
        :label="$t('person.plz')"
        :supporting-text="$t('location.placeholder')"
        :v="v.address"
      />

      <div class="entry__date">
        <basic-date-input
          v-model="formData.dateOfBirth"
          name="dateOfBirth"
          :label="birthdateText"
          :placeholder="$t('form.dateHint')"
          :supporting-text="$t('form.dateHint')"
          :v="v.dateOfBirth"
        />
      </div>

      <basic-gender
        v-model="formData.gender"
        class="entry__gender"
        name="gender"
        :age="DateTimeUtil.getAge(formData.dateOfBirth)"
        :v="v.gender"
      />

      <template v-if="formData.gender === GENDER.UNBORN">
        <div class="entry__info">
          <basic-message
            :content="$t('person.unbornMessage')"
          />
        </div>
      </template>

      <template v-else>
        <div class="entry__immigrant">
          <div class="immigrant__toggle">
            <basic-toggle
              v-model="formData.immigrant"
              name="immigrant"
              placement="left"
            >
              <span v-text="$t('screen.welcome.immigrant.title')" />
              <the-icon
                v-tooltip="{ trigger: 'click', content: $t('screen.welcome.immigrant.tooltip') }"
                art="regular"
                html-tooltip
                name="question-circle"
                prevent-input-toggle
              />
            </basic-toggle>
          </div>

          <template v-if="formData.immigrant">
            <div class="immigrant__input">
              <basic-date-input
                v-model="formData.currentRegistration"
                name="currentRegistration"
                :supporting-text="$t('form.dateHint')"
                :label="$t('screen.personalData.preInsurer.currentRegistration')"
                :v="v.currentRegistration"
              />
            </div>

            <div class="immigrant__info">
              <basic-message
                :title-label="$t('screen.welcome.immigrant.message.title')"
                :content="$t('screen.welcome.immigrant.message.text')"
              />
            </div>
          </template>
        </div>
      </template>

      <div class="entry__buttons">
        <div class="entry__button">
          <basic-button
            type="submit"
            :label="$t('entry.button')"
          />
        </div>

        <div class="entry__add">
          <basic-text-button
            :label="$t('screen.productWidget.entry.morePeople')"
            @click="$emit('open-form', formData)"
          >
            <template #trailing>
              <the-icon
                art="solid"
                name="plus"
              />
            </template>
          </basic-text-button>
        </div>

        <aide-banner :source="SOURCE.PRODUCT_WIDGET" />
      </div>
    </basic-form>
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'

import { events$, loading$ } from '@/services'
import { ReactivityUtil } from '@/utils/Reactivity'
import { ValidatorsUtil } from '@/utils/Validators'
import { DateTimeUtil } from '@/utils/DateTime'

import basketStore from '@/store/basket'

import useI18n from '@/hooks/useI18n'
import usePerson from '@/hooks/usePerson'

import {
  BasicButton,
  BasicDateInput,
  BasicForm,
  BasicGender,
  BasicLocation,
  BasicMessage,
  BasicTextButton,
  BasicToggle,
} from '@/components/Basic'
import AideBanner from '@/components/Aide/Banner'

import { CHANNEL, GENDER } from '@/config/constants'
import { EVENT_PERSONS_FORM_DISPLAYED, SOURCE } from '@/config/events'
import { contractStartDate, dateOfBirth } from '@/validations/components'

// HOOKS
const { t } = useI18n()
const { createPerson } = usePerson()

// INIT
defineEmits(['open-form'])
const formData = defineModel({
  type: Object,
  default: reactive({
    dateOfBirth: null,
    gender: null,
    address: {},
    immigrant: false,
    currentRegistration: null,
  }),
})

// COMPUTED
const birthdateText = computed(() => {
  return isUnborn.value ? t('person.estimatedBirthdate') : t('person.birthdate')
})

const isUnborn = computed(() => {
  return formData.value.gender === GENDER.UNBORN
})

// METHODS
async function submit() {
  loading$.start()

  const payload = ReactivityUtil.clone(basketStore.basket)

  const __person = await createPerson()
  __person.personData.dateOfBirth = formData.value.dateOfBirth
  __person.personData.gender = formData.value.gender

  __person.preInsurer = {}

  if (formData.value.immigrant) {
    __person.preInsurer.preInsurer = false
    __person.immigrant = true
    __person.preInsurer.currentRegistration = formData.value.currentRegistration
    __person.kvgContractStartDate = formData.value.currentRegistration
  } else {
    __person.preInsurer.currentRegistration = null
    __person.preInsurer.preInsurer = true
    __person.immigrant = false
  }

  payload.persons.push(__person)
  payload.address = formData.value.address
  if (!payload.channel) payload.channel = CHANNEL.PRODUCTWIDGET

  events$.emit(EVENT_PERSONS_FORM_DISPLAYED, { source: SOURCE.PRODUCT_WIDGET })

  try {
    await basketStore.updateOvpBasket(payload)
    loading$.end()
  } catch {
    loading$.failed()
  }
}

// VALIDATION
const v = useVuelidate(
  computed(() => ({
    address: {
      zipcode: required,
      location: ValidatorsUtil.isLocation,
    },
    dateOfBirth: {
      ...dateOfBirth,
      dateRange: function (value, model) {
        return model.gender !== GENDER.UNBORN || DateTimeUtil.isInFuture(value)
      },
    },
    gender: {
      required,
    },
    currentRegistration: {
      required: requiredIf(() => formData.value.immigrant),
      ...contractStartDate,
    },
  })),
  formData.value,
  { $autoDirty: true }
)
</script>

<style scoped>
.entry {
  display: grid;
  grid-template-areas:
    'title'
    'login'
    'form';
}

.entry__title {
  grid-area: title;
  text-align: center;
  margin: 0 0 var(--dotcom-responsive-spacing-res-xs);
}

.entry__form {
  grid-area: form;
  display: grid;
  grid-template-areas:
    'login'
    'location'
    'date'
    'gender'
    'info'
    'immigrant'
    'buttons';
}

.entry__buttons {
  grid-area: buttons;
  margin: var(--dotcom-responsive-spacing-res-m) 0 0 0;

  display: grid;
  grid-template-areas:
    'submit'
    'banner'
    'add';
  gap: var(--dotcom-responsive-spacing-res-m);

  .entry__button {
    width: 100%;
    grid-area: submit;
  }

  .entry__add {
    grid-area: add;
  }

  .entry__banner {
    grid-area: banner;
  }
}

.entry__location {
  grid-area: location;
}

.entry__date {
  grid-area: date;
}

.entry__gender {
  grid-area: gender;
  margin: 0;
}

.entry__info {
  grid-area: info;
}

.entry__immigrant {
  grid-area: immigrant;
  margin: var(--dotcom-responsive-spacing-res-xxxs) 0 0 0;
  display: grid;
  grid-template-areas:
    'toggle'
    'input'
    'info';
}

.immigrant__toggle {
  grid-area: toggle;
  display: flex;
  flex-direction: row;
  gap: var(--fixed-spacing-fix-01);
  align-items: center;
}

.immigrant__input {
  grid-area: input;
}

.immigrant__info {
  grid-area: info;
}

.entry__login {
  grid-area: login;
  margin: 0 0 var(--dotcom-responsive-spacing-res-l);
}

.entry__login:deep(.prompt) {
  align-items: center;
  justify-content: center;
}

@media (--v-medium) {
  .entry__button {
    width: auto;
    text-align: right;
  }

  .entry__login:deep(.prompt) {
    flex-direction: column;
    gap: 0;
  }
}

@media (--v-large) {
  .entry__form {
    grid-template-areas:
      'location date gender'
      'info info info'
      'immigrant immigrant immigrant'
      'login login login'
      'buttons buttons buttons';
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: var(--fixed-spacing-fix-06);
  }

  .entry__immigrant {
    grid-template-areas:
      'toggle input blank'
      'info info info';
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .entry__login:deep(.prompt) {
    flex-direction: row;
    gap: var(--fixed-spacing-fix-04);
  }

  .entry__buttons {
    grid-template-areas:
      'banner submit'
      'add .';
  }

  .entry__add {
    align-content: center;
  }

  .entry__button {
    margin: 0;
    align-self: center;
  }
}
</style>
