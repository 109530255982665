<template>
  <div
    class="entry"
    :app-language="selectedLanguage"
    :app-name="appName$"
    :app-version="version$"
  >
    <template v-if="!isReadyWithPartner">
      <loading-alt />
    </template>
    <template v-else>
      <div class="entry__header">
        <div
          class="h3 entry__title"
          v-text="$t('entry.title')"
        />

        <div
          v-if="isLoginEnabled"
          class="entry__login"
        >
          <login-action
            ref="loginRef"
            modal-id="entry-widget-login-modal"
            navigate-to-dvp
            :channel="CHANNEL.ENTRYWIDGET"
          />
        </div>
      </div>

      <basic-form
        :auto-focus="false"
        :validator="v"
        class="entry__form"
        name="entry"
        @submit="submit"
      >
        <basic-location
          v-model="formData.address"
          class="entry__location"
          name="address"
          :contract-start-date="basketStore.basket.contractStartDate"
          :disabled="hasBasket"
          :label="$t('person.plz')"
          :supporting-text="$t('location.placeholder')"
          :v="v.address"
        />

        <div class="entry__date">
          <basic-date-input
            v-model="formData.dateOfBirth"
            name="dateOfBirth"
            :disabled="hasBasket"
            :label="birthdateText"
            :placeholder="$t('form.dateHint')"
            :v="v.dateOfBirth"
          />
        </div>

        <basic-gender
          v-model="formData.gender"
          class="entry__gender"
          name="gender"
          :readonly="hasBasket"
          :age="DateTimeUtil.getAge(formData.dateOfBirth)"
          :v="v.gender"
        />

        <template v-if="formData.gender === GENDER.UNBORN">
          <div class="entry__info">
            <basic-message :content="$t('person.unbornMessage')" />
          </div>
        </template>

        <div
          class="entry__buttons"
          :class="{ 'entry__buttons--reversed': hasBasket }"
        >
          <basic-link
            v-if="hasBasket"
            class="entry__restart"
            hierarchy="tertiary"
            :label="$t('entry.restart')"
            @click="restartApplication"
          >
            <template #trailing>
              <the-icon
                art="solid"
                name="arrow-rotate-left"
              />
            </template>
          </basic-link>

          <aide-banner :source="SOURCE.ENTRY_WIDGET" />

          <basic-button
            class="entry__submit"
            type="submit"
            :label="$t('entry.button')"
          />
        </div>
      </basic-form>
    </template>
  </div>
</template>

<script setup>
import { computed, inject, reactive, ref, watchEffect } from 'vue'
import useVuelidate from '@vuelidate/core'
import { v4 as uuidv4 } from 'uuid'

import { events$, loading$ } from '@/services'

import useABConfig from '@/hooks/useABConfig'
import useApplication from '@/hooks/useApplication'
import useAuth from '@/hooks/useAuth'
import useI18n from '@/hooks/useI18n'
import useSnackbar from '@/hooks/useSnackbar'
import { ReactivityUtil } from '@/utils/Reactivity'
import { DateTimeUtil } from '@/utils/DateTime'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'

import {
  BasicButton,
  BasicDateInput,
  BasicForm,
  BasicGender,
  BasicLocation,
  BasicMessage,
  BasicLink,
} from '@/components/Basic'
import AideBanner from '@/components/Aide/Banner'
import LoadingAlt from '@/components/Container/LoadingAlt'
import { LoginAction } from '@/components/Login'

import validations from '@/validations/entryWidget'
import { CHANNEL, DEFAULT_MASTER_TREATY, GENDER, NOTIFICATION } from '@/config/constants'
import { EVENT_TRACKING, SOURCE } from '@/config/events'

// HOOKS
const { selectedLanguage, t } = useI18n()
const { getDvpUrl, isReadyWithPartner } = useApplication()
const { restartApplication } = useAuth()
const { addNotification } = useSnackbar()
const { abConfig } = useABConfig()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')
const formData = defineModel({
  type: Object,
  default: reactive({
    dateOfBirth: null,
    gender: null,
    address: {},
    personId: null,
  }),
})

// DATA
const loginRef = ref(null)

// COMPUTED
const birthdateText = computed(() => {
  return isUnborn.value ? t('person.estimatedBirthdate') : t('person.birthdate')
})

const hasBasket = computed(() => {
  return basketStore.basket.basketId && basketStore.basket.persons.length > 0
})

const isLoginEnabled = computed(() => {
  const isLoginModalActive = loginRef.value?.actionModalRef?.isActive
  return (
    (basketStore.basket.channel !== CHANNEL.ALVA &&
      !partnerStore.isPrivateInsurer.value &&
      !partnerStore.isBroker.value &&
      !partnerStore.isCollective.value &&
      !basketStore.basket.existingCustomer) ||
    isLoginModalActive
  )
})

const isUnborn = computed(() => {
  return formData.value.gender === GENDER.UNBORN
})

// METHODS
function navigate() {
  const baseUrl = getDvpUrl(selectedLanguage.value)

  let target = baseUrl
  if (abConfig.value.entryWidgetTarget) {
    target += '#' + abConfig.value.entryWidgetTarget
  }

  window.open(target, '_self').focus()
}

function submit() {
  const shouldResume = !!formData.value.personId

  if (shouldResume) {
    navigate()
  } else {
    loading$.start()

    const personId = formData.value.personId ?? uuidv4()
    const payload = {
      address: formData.value.address,
      channel: CHANNEL.ENTRYWIDGET,
      language: selectedLanguage.value.toUpperCase(),
      masterTreaty: DEFAULT_MASTER_TREATY,
      persons: [
        {
          contractModified: true,
          personId,
          immigrant: false,
          personData: {
            dateOfBirth: formData.value.dateOfBirth,
            gender: formData.value.gender,
          },
        },
      ],
    }

    return basketStore
      .updateOvpBasket(payload)
      .then(() => {
        loading$.end()
        events$.emit(EVENT_TRACKING.ENTERED, { source: CHANNEL.ENTRYWIDGET })
        navigate()
      })
      .catch(error => {
        loading$.failed()
        if (error?.source !== SOURCE.BACKEND) {
          addNotification({
            text: error,
            type: NOTIFICATION.ERROR,
          })
        }
      })
  }
}

// VALIDATION
const v = useVuelidate(validations, formData.value, { $autoDirty: true })

// LIFECYCLE HOOKS
watchEffect(() => {
  const person = basketStore.basket.persons?.[0] ?? false

  if (person) {
    formData.value.dateOfBirth = person.personData.dateOfBirth
    formData.value.gender = person.personData.gender
    formData.value.address = ReactivityUtil.clone(basketStore.basket.address)
    formData.value.personId = person.personId
  }
})
</script>

<style scoped>
.entry {
  border-radius: var(--border-radius-res-m);
  background: var(--surface);
  padding: var(--dotcom-responsive-spacing-res-m) var(--dotcom-responsive-offset-responsive);
  box-shadow: var(--elevation-level-3);
  min-width: 320px;

  display: grid;
  grid-template-areas:
    'title'
    'login'
    'form';
}

.entry__title {
  grid-area: title;
  margin: 0 0 var(--fixed-spacing-fix-04);
}

.entry__form {
  grid-area: form;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'location'
    'date'
    'gender'
    'info'
    'buttons';
}

.entry__location {
  grid-area: location;
}

.entry__date {
  grid-area: date;
}

.entry__gender {
  grid-area: gender;
}

.entry__info {
  grid-area: info;
  margin: 0 0 var(--fixed-spacing-fix-06);
}
.entry__buttons {
  grid-area: buttons;
  display: grid;
  gap: var(--fixed-spacing-fix-06);
  grid-template-areas:
    'submit'
    'banner';

  &--reversed {
    grid-template-areas:
      'submit'
      'restart'
      'banner';
  }

  .entry__restart {
    grid-area: restart;
  }

  .banner {
    grid-area: banner;
  }

  .entry__submit {
    grid-area: submit;
  }
}

.entry__restart {
  display: flex;
  justify-content: center;
}

.entry__login {
  grid-area: login;
  margin: 0 0 var(--fixed-spacing-fix-06);
  display: flex;
  align-items: center;
}

@media (--v-medium) {
  .entry {
    padding: var(--dotcom-responsive-spacing-res-m) var(--dotcom-responsive-spacing-res-xl);
  }
  .entry__form {
    grid-template-areas:
      'location location'
      'date date'
      'gender gender'
      'info info'
      'buttons buttons';
  }

  .entry__buttons {
    grid-template-areas: 'banner submit';

    &--reversed {
      grid-template-areas:
        'restart submit'
        'banner banner';
    }

    .entry__restart {
      justify-self: start;
      grid-area: restart;
    }

    .banner {
      grid-area: banner;
    }

    .entry__submit {
      justify-self: end;
      align-self: center;
      grid-area: submit;
    }
  }

  .entry__login:deep(.prompt) {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
  }
}

@media (--v-large) {
  .entry__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 var(--fixed-spacing-fix-06);
  }

  .entry__title,
  .entry__login {
    margin: 0;
  }

  .entry__form {
    grid-template-areas:
      'location date gender'
      'buttons buttons buttons'
      'info info info';
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: var(--fixed-spacing-fix-06);
  }

  .entry__restart {
    margin-right: inherit;
  }

  .entry__login:deep(.prompt) {
    align-items: center;
    flex-direction: row;
    gap: var(--fixed-spacing-fix-06);
  }
}
</style>
