<template>
  <!-- prettier-ignore -->
  <div class="pwForm">
    <basic-form
      ref="formRef"
      name="productWidgetForm"
      class="pwForm__form"
      :validator="v"
      @submit="submit"
    >
      <basic-location
        v-model="formData.address"
        class="entry__location"
        name="address"
        :contract-start-date="basketStore.basket.contractStartDate"
        :label="$t('person.plz')"
        :supporting-text="$t('location.placeholder')"
        :v="v.address"
      />

      <div class="pwForm__persons">
        <template
          v-for="(person, index) in displayPersons.mutable"
          :key="person.personId"
        >
          <a-person
            v-model="displayPersons.mutable[index]"
            class="pwForm__person"
            has-immigration-date-check
            :index="index"
            :contract-start-date="basketStore.basket.contractStartDate"
            :is-existing-customer="basketStore.basket.existingCustomer"
            :person-groups="personGroups"
            :source="SOURCE.PRODUCT_WIDGET"
            @delete="removePerson(person.personId)"
          />
        </template>
      </div>

      <basic-text-button
        v-if="allowMultiplePersons"
        class="pwForm__add"
        :disabled="formData.persons.length === MAX_PERSONS"
        :label="$t('person.addPerson')"
        @click="addPerson(false, formData.persons.length)"
      >
        <template #trailing>
          <the-icon
            art="solid"
            name="add"
          />
        </template>
      </basic-text-button>

      <basic-modal-footer :target="$props.modalRef">
        <basic-button
          class="pwForm__action"
          :label="$t('form.apply')"
          @click="formRef.submit()"
        >
          <template #trailing>
            <the-icon
              art="solid"
              name="arrow-right"
            />
          </template>
        </basic-button>
      </basic-modal-footer>
    </basic-form>

	</div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'

import { loading$ } from '@/services'

import { ReactivityUtil } from '@/utils/Reactivity'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import usePersonDetails from '@/hooks/usePersonDetails'
import usePerson from '@/hooks/usePerson'

import APerson from '@/modules/Configurator/screens/Persons/Person'
import { BasicButton, BasicForm, BasicLocation, BasicModalFooter, BasicTextButton } from '@/components/Basic'

import { CHANNEL, MAX_PERSONS } from '@/config/constants'
import validations from '@/validations/entry'
import { SOURCE } from '@/config/events.js'

// HOOKS
const { getPersonsByMutability } = usePersonDetails()
const { addOrderToPersons, createPerson } = usePerson()

// INIT
const emit = defineEmits(['close-modal'])
const props = defineProps({
  initialPerson: {
    type: Object,
    default: () => {},
  },

  modalRef: {
    type: Object,
    required: true,
  },
})

const formData = reactive({
  address: {},
  contractNumber: null,
  persons: [],
})

// DATA
const formRef = ref()

// COMPUTED
const allowMultiplePersons = computed(() => {
  // if there are existing customers selected to edit do not allow to add further persons
  return !displayPersons.value.mutable.some(p => p.partnerNumber)
})

const displayPersons = computed(() => {
  return getPersonsByMutability(formData.persons)
})

const personGroups = computed(() => {
  if (formData.contractNumber) {
    return partnerStore.partner.collectiveContractConfig?.personGroups
  }
  return []
})

// METHODS
async function addPerson(data = false, index = 0) {
  const __person = await createPerson()

  if (data) {
    __person.personData.dateOfBirth = data.dateOfBirth
    __person.personData.gender = data.gender

    __person.immigrant = data.immigrant
    __person.preInsurer = __person.preInsurer || {}
    __person.preInsurer.preInsurer = false
    __person.preInsurer.currentRegistration = data.currentRegistration
  }

  formData.persons.push(__person)

  if (index > 0) {
    await nextTick()

    document.querySelector('.pwForm__persons').children[index].scrollIntoView({
      behaviour: 'smooth',
    })
  }
}

function removePerson(personId) {
  formData.persons = formData.persons.filter(p => p.personId !== personId)

  /*
    displayPersons is a computed, that is updated on formData.persons changes, so we get the
    updated mutable persons after removing the person from formData.persons
   */
  const personsLeft = displayPersons.value.mutable
  if (personsLeft.length === 0) {
    addPerson()
  }
}

async function submit() {
  loading$.start()

  let payload = Object.assign({}, basketStore.basket, ReactivityUtil.clone(formData))
  payload.persons = addOrderToPersons(payload.persons)

  if (!payload.channel) payload.channel = CHANNEL.PRODUCTWIDGET

  try {
    await basketStore.updateOvpBasket(payload)

    await nextTick()

    emit('close-modal')

    loading$.end()
  } catch {
    loading$.failed()
  }
}

// VALIDATION
const v = useVuelidate(validations, formData, { $autoDirty: true })

// LIFECYCLE HOOKS
onBeforeMount(() => {
  const { address, persons } = basketStore.basket

  ReactivityUtil.reAssign(formData.address, address || {})
  ReactivityUtil.reAssign(formData.persons, persons || {})

  if (persons.length === 0) {
    if (props.initialPerson?.address?.displayName) {
      formData.address = props.initialPerson.address
    }

    addPerson(props.initialPerson)
  }
})
</script>

<style scoped>
.pwForm {
  display: flex;
  flex-direction: column;
  gap: var(--dotcom-responsive-spacing-res-m);

  .pwForm__persons {
    display: flex;
    flex-direction: column;
    gap: var(--dotcom-responsive-spacing-res-m);
  }

  .pwForm__add {
    margin: var(--dotcom-responsive-spacing-res-m) 0;
  }
}
</style>
